import React from "react"
import Highlighter from "react-highlight-words"
import Box from '../components/box'
import { Container, Row, Col } from 'reactstrap'
import styled from 'styled-components'


const BoxWrapper = styled(Box)`
margin-top: 3rem;
height: 100px;
padding-left: 2rem;
`


const SearchResults = ({ query, results }) => (
 
  <Container className="py-5">
    <section aria-label="Search results for all posts">
    {query && (
      <h2
        className="search-results-count"
        id="search-results-count"
        aria-live="assertive"
      >
          Se encontraron {results.length} tramites con la palabra "{query}"
      </h2>
    )}
    {!!results.length && (
      <Row>
        <ol className="search-results-list">
        {results.map(({ title, url, description, shortDescription }) => (
                <Col>
          <BoxWrapper>
            <li key={title}>
              <h3 className="search-results-list__heading">
                <a href={url} className="search-results-list__link">
                  <Highlighter
                    searchWords={[query]}
                    autoEscape={true}
                    textToHighlight={title}
                  />
                </a>
              </h3>
              {shortDescription && (
                <p>
                  <Highlighter
                    searchWords={[query]}
                    autoEscape={true}
                    textToHighlight={shortDescription}
                  />
                </p>
              )}
              {description && (
                <p>
                  <Highlighter
                    searchWords={[query]}
                    autoEscape={true}
                    textToHighlight={description}
                  />
                </p>
              )}
            </li>
            </BoxWrapper>
            </Col>

        ))}
      </ol>
</Row>      
    )}
    </section>
    </Container>
)

export default SearchResults
