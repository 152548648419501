import React, { useState, useEffect } from "react"
import debounce from "lodash.debounce"
import Layout from "../components/layout"
import SearchForm from "../components/searchForm"
import SearchResults from "../components/searchResults"
import { Container } from 'reactstrap'
import Breadcrumb  from "../components/breadcrumb"
import useSiteMetadata from '../hooks/use-site-config'
import { Helmet } from "react-helmet"


const Search = ({ data, location, pageContext }) => {
  const { siteTitle } = useSiteMetadata()

  const [results, setResults] = useState([])
  let searchQuery = ""
  if(typeof window !== `undefined`) {
    searchQuery = new URLSearchParams(location.search).get("keywords") || ""
  }
  //const searchQuery = new URLSearchParams(location.search).get("keywords") || ""
  useEffect(() => {
    if (searchQuery && window.__LUNR__) {
      const debouncedSearch = debounce(async () => {
        const lunr = await window.__LUNR__.__loaded
        const refs = lunr.en.index.search(searchQuery)
        const posts = refs.map(({ ref }) => lunr.en.store[ref])

        setResults(posts)
      }, 500)

      debouncedSearch()
    }

    if (!searchQuery) setResults([])
  }, [location.search])

  return (
    <Layout >
        <Helmet>
          <meta charSet="utf-8" />
           <title>Buscar | {siteTitle}</title>
        </Helmet>
      <Container>
      <SearchForm query={searchQuery} />
      <SearchResults query={searchQuery} results={results} />
      </Container>
      <Breadcrumb pageContext={pageContext} label="Busqueda"/>
    </Layout>
  )
}

export default Search

