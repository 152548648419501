import React from 'react'
import styled from "styled-components"

const Box = styled.div`
  height: 120px;
  padding-left: 1rem;
  border-color: #000000;
  transition-duration: 0.3s;
  text-align: left;
  border-left: 2px solid #00000029;

`

export default ({children, ...opts}) => (
  <Box {...opts}>
    {children}
  </Box>
)
